@forward './index';
@forward './common';

@use './variables/sizes' as sizes;

:root {
  --scroll-bar-width-legacy: 4px;
  --scroll-bar--hover-width-legacy: var(--scroll-bar-width-legacy);
  --scroll-bar-width: thin;
  --scrollbar-color-thumb: var(--color-outline-variant);
  --scrollbar-color-hover-thumb: var(--scrollbar-color-thumb);
  --scrollbar-color-track: transparent;
  --scrollbar-display: block;
  --padding-none: 0;
  --padding-petite: #{sizes.$padding-petite};
  --padding-xxs: #{sizes.$padding-xxs};
  --padding-xs: #{sizes.$padding-xs};
  --padding-sm: #{sizes.$padding-sm};
  --padding-md: #{sizes.$padding-md};
  --padding-lg: #{sizes.$padding-lg};
  --padding-xl: #{sizes.$padding-xl};
  --padding-xxl: #{sizes.$padding-xxs};
  --spacing-none: #{sizes.$spacing-none};
  --spacing-xxs: #{sizes.$spacing-xxs};
  --spacing-xs: #{sizes.$spacing-xs};
  --spacing-sm: #{sizes.$spacing-sm};
  --spacing-md: #{sizes.$spacing-md};
  --spacing-lg: #{sizes.$spacing-lg};
  --spacing-xl: #{sizes.$spacing-xl};
  --spacing-xxl: #{sizes.$spacing-xxl};
  --radius-round: #{sizes.$radius-round};
  --radius-xl: #{sizes.$radius-xl};
  --radius-lg: #{sizes.$radius-lg};
  --radius-md: #{sizes.$radius-md};
  --radius-sm: #{sizes.$radius-sm};
  --radius-xs: #{sizes.$radius-xs};
  --radius-none: #{sizes.$radius-none};
  --th-table-background: var(--color-surf) !important;
  --th-table-radius: 0.5rem;
}

html {
  font-size: 16px; // 16px = 1rem
}

html,
body {
  overflow: hidden;
  color: var(--color-on-surf);
  background-color: var(--color-surf-container-low) !important;
}

* {
  @media print {
    overflow: initial;
  }

  @supports not selector(::-webkit-scrollbar) {
    @supports (scrollbar-width: auto) {
      scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
      scrollbar-width: var(--scrollbar-width);
    }
  }

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      display: block;
      width: var(--scroll-bar-width-legacy, 6px);
      height: var(--scroll-bar-width-legacy, 6px);
      background-color: transparent;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
      background-color: var(--scrollbar-color-track, transparent);
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-color-thumb, #a0a0a5);
      border-radius: 16px;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }

    /* scrollbar when element is hovered */
    &:hover {
      &::-webkit-scrollbar {
        width: var(
          --scroll-bar--hover-width-legacy,
          var(--scroll-bar--hover-width-legacy, 6px)
        ) !important;
        height: var(
          --scroll-bar--hover-width-legacy,
          var(--scroll-bar--hover-width-legacy, 6px)
        );
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-color-hover-thumb);
      }
    }

    /* scrollbar when scrollbar is hovered */
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--scrollbar-color-hover-thumb);
    }
  }
}
